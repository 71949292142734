import { ReactComponent as GoogleIcon } from "../assets/google-logo.svg";
import { ReactComponent as GithubIcon } from "../assets/github.svg";
import logoImage from '../assets/embed-logo.png'
import {Image, Row, Col, Button, Stack} from 'react-bootstrap'
import { useState,useEffect,useRef } from "react";
import axios from "axios";
import { sendAnalyticsEvent } from "../utils/utility";
import { ToastContainer,toast } from "react-toastify";

function EmbedAIBotSelectCrisp(){
    document.title = "Welcome to EmbedAI"
    const [selectedKb, setSelectedKb] = useState('');
    const [gotoEmbedAI, setgotoEmbedAI] = useState(false);    
    const [KbOptions, setKbOptions] = useState([]);
    const params = new URLSearchParams(window.location.search);

    const handleKbChange = (event) => {
        console.log("Selected", event.target.value);
        setSelectedKb(event.target.value);
    };

    useEffect(() => {
        var url = '/heybotnew/fetch_all_kb_botselect_crisp?website_id='+params.get("website_id")+"&token="+params.get("token")
        axios.get(url)
            .then(response => {
                setKbOptions(response.data); 
            })
            .catch(error => {
                console.error('Error fetching bot options:', error);
                toast.error("Login to EmbedAI by clicking on button below before setting up");
                setgotoEmbedAI(true);
            });
    }, []);

    const gotoEmbedAIURL = () => {
        window.open("https://embedai.thesamur.ai", "_blank");
    };

    const handleSubmit = () => {
        if (selectedKb) {
            const requestData = {kbid: selectedKb, website_id: params.get("website_id"), token: params.get("token")};
            axios.post('/heybotnew/select_crisp_kb', requestData)
                .then(response => {
                    console.log(response.data); // Handle successful response
                    toast.success("Successfully connected");
                })
                .catch(error => {
                    console.error('Error sending POST request:', error);
                    toast.error("Invalid params");
                });
        }
    };

    return(
        <div className="heybotnew-bg  p-0 p-lg-3 ">
            
            <div className="heybotnew-box posittion-relative d-flex flex-column heybotnew-theme-light w-100 h-100 p-3 p-lg-5" >
                <div className="d-flex flex-wrap justify-content-between">
                    <div class="d-flex align-items-center gap-2">
                        <Image className="heybotnew-welcome-logo" src={logoImage}/>
                        <h3 className="heybotnew-welcome-title">EmbedAI</h3>
                    </div>
                </div>
                <div className="d-flex flex-column align-items-center my-5">
                    <h4 className="py-4">Setup EmbedAI bot</h4>
                    <select
                        id="kbSelect"
                        name="kbSelect"
                        value={selectedKb}
                        onChange={handleKbChange}
                    >
                        <option value="">Select a Knowledebase</option>
                        {KbOptions.map((option) => (
                            <option key={option.id} value={option.id}>
                                {option.name}
                            </option>
                        ))}
                    </select>
                    {!gotoEmbedAI ? (
                    <Button className="p-2  mt-2 me-2 heybotnew-button border-0" onClick={handleSubmit} disabled={!selectedKb}>
                        Submit
                    </Button>
                    ):(
                    <Button className="p-2  mt-2 me-2 heybotnew-button border-0" onClick={gotoEmbedAIURL}>
                        Go to EmbedAI
                    </Button>
                    )}
                </div>
            </div>
            <ToastContainer/>
        </div>
    )
}
export default EmbedAIBotSelectCrisp
