import { useState,useRef, useEffect } from "react";
import {Button,Stack, FormControl, Image, Card,Form } from 'react-bootstrap'
import { ReactComponent as SendIcon } from "../assets/send-heybot.svg";
import '../styles/heybotnew.css'
import { useParams,useSearchParams } from "react-router-dom";
import axios from "axios";
import logoImage from '../assets/embed-logo.png'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { isStringValid,validateEmail } from "../utils/utility";
import Amplify from '@aws-amplify/core'
import * as gen from './generated'

Amplify.configure(gen.config)

function HeyBotNewEmbed () {
    const { slug } = useParams();
    document.title = slug
    const [theme,setTheme] = useState(0);
    const [simple,setSimple] = useState(false);
    const [subscription,setSubscription] = useState(null);
    const [sessId,setSessId] = useState(0);
    const [live,setLive] = useState(false);
    const [update,setUpdate] = useState(false);
    const [duration,setDuration] = useState(0);
    const [removeWatermark,setRemoveWatermark] = useState(false);
    const [botColor,setBotColor] = useState({bg:"#ABE4F8",text:"black"});
    const [userColor,setUserColor] = useState({bg:"#000000",text:"white"});
    const [initMsg,setInitMsg] = useState("");
    const [suggestions,setSuggestions] = useState(["What can you do?","What is this site about?"]);
    const [embedLogo,setEmbedLogo] = useState("");
    let [searchParams, setSearchParams] = useSearchParams();
    const [streamAns, setStreamAns] = useState(null);
    const [chat,setChat] = useState([]);
    const [question, setQuestion] = useState("");
    const[isLeadGenerationEnabled,setIsLeadGenerationEnabled] = useState(0)
    const [isLeadCaptured,setIsLeadCaptured] = useState(false)
    const [isLeadMessageSent,setisLeadMessageSent] = useState(false)
    const [isLeadNameValidated, setIsLeadNameValidated] = useState(true);
    const [isLeadEmailValidated, setIsLeadEmailValidated] = useState(true);
    const [leadProgress, setLeadProgress] = useState(false);
    const chatRef = useRef(null)
    chatRef.current = chat
    const streamRef = useRef(null)
    streamRef.current = streamAns

    let recordConvo = () => {
        axios.post("/heybotnew/record_convo",{sessId:sessId,slug:slug}).then((res)=>{
            }).catch((err)=>{
            console.log(err)
        })
    }

    let askKBase = async() => {
        if(question!=""){
            if(chatRef.current.length<=1){
                setDuration(Date.now())
            }
            if(isLeadMessageSent){
                setChat([...chatRef.current,{isBot:false,msg:question}])
            }else{
                setChat([...chatRef.current,{isBot:false,msg:question},{isBot:false,msg:"generate-lead"}])
            }
            let getQuestion = question
            setQuestion("")
            var productLinks = []
            //var slug = "vadootv"
            var is_taken_over = false;
            if(sessId!=0) {
                const res = await axios.get("/heybotnew/is_taken_over", {params: {sessId:sessId}})
                is_taken_over = res.data
            }
            if(is_taken_over==false){
                setStreamAns("")
                //const eventSource = new EventSource('http://localhost:9000/api?question='+getQuestion+'&slug='+slug)
                const eventSource = new EventSource('https://embedai.vercel.app/api?question='+getQuestion+'&slug='+slug)
                eventSource.onmessage = function (event) {
                    var getData = JSON.parse(event.data)
                    console.log("Data", getData, getData.hasOwnProperty('text'));
                    if(getData.hasOwnProperty('text')){
                        var out = getData.text.replaceAll("--*", "\n")
                        console.log("Data out", out)
                        setStreamAns((prev)=> prev + out)
                    }else{
                        productLinks.push({url:getData.url,title:getData.title,image:getData.image,description:getData.description})
                        // let out = JSON.parse()
                        // console.log(out)
                    }
                }

                eventSource.onerror = () => {
                    if(streamRef.current==""){
                        setChat([...chatRef.current,{isBot:false,msg:"An error occured, please try again"}])
                        setQuestion(getQuestion)
                    }else{
                        let currentStream = streamRef.current
                        if(productLinks.length>0){
                            setChat([...chatRef.current,{isBot:true,msg:currentStream,linkPreview:productLinks}]);
                            setStreamAns((prev)=>null);
                        }else{
                            var matches = currentStream.match(/\bhttps?:\/\/\S+/gi);
                            if (matches!=null && matches.length>0){
                                axios.post("/heybotnew/preview_link",{link:matches[0]}).then((res)=>{
                                    setChat([...chatRef.current,{isBot:true,msg:currentStream,linkPreview:[res.data]}]);
                                    setStreamAns((prev)=>null);
                                }).catch((err)=>{
                                    console.log(err)
                                })
                            }else{
                                setChat([...chatRef.current,{isBot:true,msg:currentStream,linkPreview:null}]);
                                setStreamAns((prev)=>null);
                            }
                        }
                    }
                    eventSource.close()
                }
            }else{
                let data = `{"msg":"${getQuestion}","fromAdmin":false}`
                await gen.publish(slug+'-'+sessId, data)
            }
        }
    }    

    useEffect(()=>{
        if(sessId!=0){
            setSubscription(gen.subscribe(slug+'-'+sessId, ({ data }) => {
                let parseData = JSON.parse(data)
                if(parseData["fromAdmin"]==true){
                    setChat([...chatRef.current,{isBot:true,msg:parseData["msg"]}]);
                }
            }))

        }
        return () => {
            if(subscription!=null){subscription.unsubscribe()}
        }
    },[sessId])
    
    
    useEffect(()=>{
        if(searchParams.get("simple")!=null){
          setSimple((prev)=>true)
        }
    },[])

    useEffect(()=>{
        if(slug=='embedai-writeup'){
            setInitMsg("Welcome to EmbedAI, ask me anything!")
            setSuggestions(["What is EmbedAI?","What does EmbedAI do?","What types of documents can I provide?"])
        }else{
            axios.get("/heybotnew/get_chatbot_info",{params:{slug:slug}}).then((res)=>{
                if(res.data.theme[0]==='dark'){
                    setTheme(1)
                }
                setBotColor({bg:res.data.theme[1],text:res.data.theme[2]})
                setUserColor({bg:res.data.theme[3],text:res.data.theme[4]})
                setInitMsg(res.data.initial_message)
                setSuggestions(res.data.suggestions)
                setEmbedLogo(res.data.embed_logo)
                setRemoveWatermark(res.data.remove_watermark)
                setIsLeadGenerationEnabled(res.data.lead_generation_enabled)
                }).catch((err)=>{
                console.log(err)
            })
        }
        window.addEventListener("beforeunload", recordConvo);
    },[])
      
    useEffect(() => {
        if (document.querySelector(".end-chat") != null) {
          document
            .querySelector(".end-chat")
            .scrollIntoView({ behavior: "smooth", block: "end" });
        }
        if(chat.length>1){
            axios.post("/heybotnew/record_embed_convo",{slug:slug,chat:chatRef.current[chatRef.current.length - 1],sessId:sessId}).then((res)=>{
                setSessId(res.data.sessId)
            }).catch((err)=>{
            console.log(err)
        })
        }
      }, [chat]);

    useEffect(()=>{
        console.log("Chat length", chat.length, initMsg);
        if(chat.length==0 && initMsg!=""){
            setChat([...chat,{isBot:true,msg:initMsg}])
        }

        if (chat) {
            chat.forEach(refItem => {
                console.log(refItem['msg']==="generate-lead")
              if (refItem.hasOwnProperty('msg') && refItem['msg'] === 'generate-lead') {
                setisLeadMessageSent(true)
              } 
            });
          }
      }, [chat,streamAns,initMsg]);

      const handleLeadCaptureClick = (event)=>{
       
        //setIsLeadCaptured(true)
      
        const leadName = document.getElementById("formBasicName").value;
        const leadEmail = document.getElementById("formBasicEmail").value;
        console.log(leadName)
        console.log(leadEmail)
        

        if(!isStringValid(leadName)){
            setIsLeadNameValidated(false)
        }else{
            setIsLeadNameValidated(true)
        }

        if(!isStringValid(leadEmail) || !validateEmail(leadEmail)){
            setIsLeadEmailValidated(false)
           
        }else{
            setIsLeadEmailValidated(true)
        }

        if(isStringValid(leadName) && isStringValid(leadEmail) && validateEmail(leadEmail)){
            setLeadProgress(true)
            setIsLeadEmailValidated(true)
            setIsLeadNameValidated(true)

            axios.post("/heybotnew/save_lead_data",{name:leadName,email:leadEmail,slug:slug}).then((res)=>{
            if(res.data.status==="fail"){
                setLeadProgress(false)
            }else{
                setIsLeadCaptured(true)
                setLeadProgress(false)
            }
            }).catch((err)=>{
            console.log(err)
        })
        }else{
            setLeadProgress(false)
        }
    }

    return(
        <>
            {simple==true?<div className={`heybotnew-box d-flex justify-content-center ${theme==0?"heybotnew-theme-light":"heybotnew-theme-dark"}`}>
            <Stack className="heybotnew-simple-embed embed-shadow align-items-center  p-4" gap={3}>
                <div className="d-flex flex-column gap-4 heybotnew-scroll w-100 heybotnew-chat-section flex-grow-1" style={{flex:'1 1 0'}}>
                            {chat.length>0&&chat.map((msg)=>
                                <>
                                {msg.msg!="generate-lead"?<>
                                <div style={msg.isBot?{background:botColor.bg,color:botColor.text,borderRadius:'1rem',borderTopLeftRadius:0}:{background:userColor.bg,color:userColor.text,borderRadius:'1rem',borderTopRightRadius:0}} className={`${msg.isBot ? "align-self-start" :"align-self-end"} heybotnew-msg-container`}>
                                        {msg.linkPreview!=null&& 
                                        <a className="text-dark text-decoration-none" target="_blank" href={msg.linkPreview.url}>
                                            <Card className="heybotnew-preview-card mb-2">
                                                <Card.Img className="heybotnew-preview-image" variant="top" src={msg.linkPreview.image} />
                                                <Card.Body className="p-2">
                                                    <Card.Title className="heybotnew-preview-title">{msg.linkPreview.title}</Card.Title>
                                                    <Card.Text className="heybotnew-preview-desc">
                                                        {msg.linkPreview.description}
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </a>}
                                        <div className="heybotnew-answer "><ReactMarkdown  children={msg.msg}   remarkPlugins={[remarkGfm]} ></ReactMarkdown></div>   
                                    </div>
                                </>:<>{ isLeadGenerationEnabled===1 && isLeadCaptured===false?<>
                                <div style={{background:botColor.bg,color:botColor.text,borderRadius:'1rem',borderTopLeftRadius:0,minWidth:"400px"}} className={`align-self-start heybotnew-msg-container`}>
                                <div className="mb-4 d-flex justify-content-between">
                                <div><b>How to contact you?</b></div>
                                <div onClick={()=>setIsLeadCaptured(true)}>X</div>
                                </div>
                                <Form >
                                <Form.Group className="mb-3" controlId="formBasicName">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Name"  required/>
                                  {isLeadNameValidated?"":<span style={{fontSize:"13px",color:"red"}}>Enter a valid name</span>}
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" placeholder="Enter email"  required/>
                                   
                                    {isLeadEmailValidated?"":<span style={{fontSize:"13px",color:"red"}}>Enter a valid email</span>}
                                    
                                </Form.Group>
                                
                                <div className="d-flex align-items-end justify-content-end">
                                <Button id="leadSubmitBtn" onClick={(e)=>handleLeadCaptureClick(e)} disabled={leadProgress} className={`p-2  mt-2 me-2 heybotnew-button heybotnew-send-btn border-0 ${theme===1?"heybotnew-theme-light":"heybotnew-theme-dark"}`} variant="primary">
                                <svg className="heybotnew-image" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id="send-alt" class="icon glyph" fill={theme==0?"#FFF0EE":"#362f2e"}>
                                <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                                <g id="SVGRepo_iconCarrier">
                                <path d="M21.88,4.73,16.2,20.65A2,2,0,0,1,14.3,22h0a2,2,0,0,1-1.9-1.31l-2.12-5.52,1.54-1.54,2.49-2.49a1,1,0,1,0-1.42-1.42l-2.49,2.49L8.82,13.76,3.31,11.63a2,2,0,0,1,0-3.83L19.27,2.12a2,2,0,0,1,2.61,2.61Z" style={theme==0?{fill:'#FFF0EE'}:{fill:'#362f2e'}}/>
                                </g>
                            </svg>
                                </Button>
                                </div>
                                </Form>
                                </div>
                                
                            </>:""}</>}
                                </>
                                    
                                )}
                                {streamAns!=null&&<div style={{background:botColor.bg,color:botColor.text,borderRadius:'1rem',borderTopLeftRadius:0}} className={`align-self-start heybotnew-msg-container ${theme==0?"theme-light":"theme-dark"}`}>
                                <div className="heybotnew-answer">{streamAns==""?<span  className="blinking-cursor"> █</span>:<ReactMarkdown className="answer-typing" children={streamAns} remarkPlugins={[remarkGfm]} />}</div>
                                </div>}
                                <div className="end-chat mt-auto"></div>
                </div>
                <div class="d-flex flex-column heybotnew-input-container  overflow-hidden w-100 pt-2 gap-2">
                    <div class="d-flex suggestion-scroll overflow-auto gap-1">{suggestions.map((suggestion)=><>
                        {suggestion!=="" ? <div onClick={()=>setQuestion(suggestion)} style={{background:botColor.bg,color:botColor.text}} className={`border text-nowrap heybotnew-answer heybotnew-suggestion`}>{suggestion}</div>:""}
                    </>)}</div>
                    <div class="d-flex position-relative">
                        <FormControl onKeyDown={(e) => {
                        e.code == "Enter" && !e.shiftKey && askKBase();
                    }} value={question} onChange={(e)=>setQuestion(e.target.value)} className={`px-3 pe-5 py-2 heybotnew-input ${theme==0?"theme-light":"theme-dark"}`} placeholder="Ask a question"/>
                        <Button onClick={askKBase} className={`p-2 position-absolute end-0 mt-2 me-2 heybotnew-button heybotnew-send-btn border-0 ${theme===1?"heybotnew-theme-light":"heybotnew-theme-dark"}`} variant="primary">
                            <svg className="heybotnew-image" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id="send-alt" class="icon glyph" fill={theme==0?"#FFF0EE":"#362f2e"}>
                                <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                                <g id="SVGRepo_iconCarrier">
                                <path d="M21.88,4.73,16.2,20.65A2,2,0,0,1,14.3,22h0a2,2,0,0,1-1.9-1.31l-2.12-5.52,1.54-1.54,2.49-2.49a1,1,0,1,0-1.42-1.42l-2.49,2.49L8.82,13.76,3.31,11.63a2,2,0,0,1,0-3.83L19.27,2.12a2,2,0,0,1,2.61,2.61Z" style={theme==0?{fill:'#FFF0EE'}:{fill:'#362f2e'}}/>
                                </g>
                            </svg>
                        </Button>
                    </div>
                </div>
                {removeWatermark==false&&<div className="d-flex justify-content-end align-items-end" style={{fontSize:"12px"}}><small>Powered by <a href="https://embedai.thesamur.ai/" target="_blank" style={theme==0?{color:'#000000'}:{color:'#ffffff'}} rel="noreferrer">EmbedAI</a></small></div>          }      
            </Stack>
            </div>:
            <div className="position-fixed bottom-0 end-0 mb-lg-4 d-flex flex-column gap-2 w-100">
                <div className={`heybotnew-embed-chat heybotnew-box heybotnew-embed embed-shadow  ${theme==0?"heybotnew-theme-light":"heybotnew-theme-dark"} p-3`}>
                    <Stack className="h-100" gap={3}>
                        <div className="d-flex flex-column gap-4 heybotnew-scroll heybotnew-chat-section flex-grow-1" style={{flex:'1 1 0'}}>
                        {chat.length>0&&chat.map((msg)=>
                        <> {msg.msg!="generate-lead"?<>
                        <div style={msg.isBot?{background:botColor.bg,color:botColor.text,borderRadius:'1rem',borderTopLeftRadius:0}:{background:userColor.bg,color:userColor.text,borderRadius:'1rem',borderTopRightRadius:0}} className={`${msg.isBot ? "align-self-start" :"align-self-end"} heybotnew-msg-container`}>
                                        {msg.linkPreview!=null&& 
                                        <a className="text-dark text-decoration-none" target="_blank" href={msg.linkPreview.url}>
                                            <Card className="heybotnew-preview-card mb-2">
                                                <Card.Img className="heybotnew-preview-image" variant="top" src={msg.linkPreview.image} />
                                                <Card.Body className="p-2">
                                                    <Card.Title className="heybotnew-preview-title">{msg.linkPreview.title}</Card.Title>
                                                    <Card.Text className="heybotnew-preview-desc">
                                                        {msg.linkPreview.description}
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </a>}
                                        <div className="heybotnew-answer "><ReactMarkdown  children={msg.msg}   remarkPlugins={[remarkGfm]} ></ReactMarkdown></div>
                                </div>
                        </>:<>{ isLeadGenerationEnabled===1 && isLeadCaptured===false?<>
                                <div style={{background:botColor.bg,color:botColor.text,borderRadius:'1rem',borderTopLeftRadius:0,minWidth:"400px"}} className={`align-self-start heybotnew-msg-container`}>
                                <div className="mb-4 d-flex justify-content-between">
                                <div><b>How to contact you?</b></div>
                                <div onClick={()=>setIsLeadCaptured(true)}>X</div>
                                </div>
                                <Form >
                                <Form.Group className="mb-3" controlId="formBasicName">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Name"  required/>
                                  {isLeadNameValidated?"":<span style={{fontSize:"13px",color:"red"}}>Enter a valid name</span>}
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" placeholder="Enter email"  required/>
                                   
                                    {isLeadEmailValidated?"":<span style={{fontSize:"13px",color:"red"}}>Enter a valid email</span>}
                                    
                                </Form.Group>
                                
                                <div className="d-flex align-items-end justify-content-end">
                                <Button id="leadSubmitBtn" onClick={(e)=>handleLeadCaptureClick(e)} disabled={leadProgress} className={`p-2  mt-2 me-2 heybotnew-button heybotnew-send-btn border-0 ${theme===1?"heybotnew-theme-light":"heybotnew-theme-dark"}`} variant="primary">
                                <svg className="heybotnew-image" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id="send-alt" class="icon glyph" fill={theme==0?"#FFF0EE":"#362f2e"}>
                                <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                                <g id="SVGRepo_iconCarrier">
                                <path d="M21.88,4.73,16.2,20.65A2,2,0,0,1,14.3,22h0a2,2,0,0,1-1.9-1.31l-2.12-5.52,1.54-1.54,2.49-2.49a1,1,0,1,0-1.42-1.42l-2.49,2.49L8.82,13.76,3.31,11.63a2,2,0,0,1,0-3.83L19.27,2.12a2,2,0,0,1,2.61,2.61Z" style={theme==0?{fill:'#FFF0EE'}:{fill:'#362f2e'}}/>
                                </g>
                            </svg>
                                </Button>
                                </div>
                                </Form>
                                </div>
                                
                            </>:""}</>}</>
                                
                            )}
                            {streamAns!=null&&<div style={{background:botColor.bg,color:botColor.text,borderRadius:'1rem',borderTopLeftRadius:0}} className={`align-self-start heybotnew-msg-container ${theme==0?"theme-light":"theme-dark"}`}>
                                <div className="heybotnew-answer">{streamAns==""?<span  className="blinking-cursor"> █</span>:<ReactMarkdown className="answer-typing" children={streamAns} remarkPlugins={[remarkGfm]} />}</div>
                            </div>}
                            <div className="end-chat mt-auto"></div>
                        </div>
                        <div class="d-flex flex-column heybotnew-input-container  overflow-hidden w-100 pt-2 gap-2">
                            <div class="d-flex suggestion-scroll overflow-auto gap-1">{suggestions.map((suggestion)=><>
                                {suggestion!="" ?<div onClick={()=>setQuestion(suggestion)} style={{background:botColor.bg,color:botColor.text}} className={`border text-nowrap heybotnew-answer heybotnew-suggestion`}>{suggestion}</div>:""}
                            </>)}</div>
                            <div class="d-flex position-relative">
                                <FormControl onKeyDown={(e) => {
                                e.code == "Enter" && !e.shiftKey && askKBase();
                            }} value={question} onChange={(e)=>setQuestion(e.target.value)} className={`px-3 pe-5 py-lg-2 heybotnew-input ${theme==0?"theme-light":"theme-dark"}`} placeholder="Ask a question"/>
                                <Button onClick={askKBase} className={`p-2 position-absolute end-0 mt-2 me-2 heybotnew-button heybotnew-send-btn border-0 ${theme==1?"heybotnew-theme-light":"heybotnew-theme-dark"}`} variant="primary">
                                    <svg className="heybotnew-image" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id="send-alt" class="icon glyph" fill={theme==0?"#FFF0EE":"#362f2e"}>
                                        <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                                        <g id="SVGRepo_iconCarrier">
                                        <path d="M21.88,4.73,16.2,20.65A2,2,0,0,1,14.3,22h0a2,2,0,0,1-1.9-1.31l-2.12-5.52,1.54-1.54,2.49-2.49a1,1,0,1,0-1.42-1.42l-2.49,2.49L8.82,13.76,3.31,11.63a2,2,0,0,1,0-3.83L19.27,2.12a2,2,0,0,1,2.61,2.61Z" style={theme==0?{fill:'#FFF0EE'}:{fill:'#362f2e'}}/>
                                        </g>
                                    </svg>
                                </Button>
                            </div>
                        </div>
                        {removeWatermark==false&&<div className="d-flex justify-content-end align-items-end" style={{fontSize:"12px"}}><small>Powered by <a href="https://embedai.thesamur.ai/" target="_blank" style={theme==0?{color:'#000000'}:{color:'#ffffff'}} rel="noreferrer">EmbedAI</a></small></div>}
                    </Stack>
                </div>

            </div>}
        </>
    )

}
export default HeyBotNewEmbed;
