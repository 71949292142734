import { useState,useEffect,useRef } from "react";
import {Row,Col, Image, Button,Form, Stack,Card, FloatingLabel, FormControl, Offcanvas,Modal,ProgressBar, ButtonGroup, ToggleButton } from 'react-bootstrap'
import logoImage from '../assets/embedai-logo.png'
import { ReactComponent as MenuIcon } from "../assets/menu-black.svg";
import { ReactComponent as EmbedIcon } from "../assets/embed-white.svg";
import { ReactComponent as LinkIcon } from "../assets/link-white.svg";
import { ReactComponent as GoogleIcon } from "../assets/google-logo.svg";
import { ReactComponent as CheckIcon } from "../assets/check-circle-icon.svg";
import { ReactComponent as UpgradeIcon } from "../assets/upgrade.svg";
import { ReactComponent as GithubIcon } from "../assets/github.svg";
import { ReactComponent as NotionIcon } from "../assets/notion-icon.svg";
import { ReactComponent as ShopifyIcon } from "../assets/shopify.svg";
import { ReactComponent as IntegrationCheckIcon } from "../assets/check-green.svg";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { ReactComponent as PenIcon } from "../assets/pen.svg";
import '../styles/heybotnew.css'
import Navbar from "../components/heybotnew/Navbar";
import { FileUploader } from "react-drag-drop-files";
import axios from "axios";
import { ReactComponent as PlusIcon } from "../assets/plus-black.svg";
import { ToastContainer,toast } from "react-toastify";
import { useSearchParams, useRouter, useNavigate, useLocation } from "react-router-dom";
import { isStringValid, sendAnalyticsEvent, validateEmail } from "../utils/utility";
import Amplify from '@aws-amplify/core'
import * as gen from './generated'
import { LoadScript } from 'react-load-script';

Amplify.configure(gen.config)


function HeyBotNew () {
    document.title = "EmbedAI"
    let [searchParams, setSearchParams] = useSearchParams();
    const [theme,setTheme] = useState(false);
    const [KBases,setKBases] = useState([]);
    const [currentKBase,setCurrentKBase] = useState(null);
    const [subscription,setSubscription] = useState(null);
    const [subscriptionId,setSubscriptionId] = useState(null);    
    const [session,setSession] = useState({id:0,msg:''});
    const [showMenu, setShowMenu] = useState(false);
    const [showNewKBase, setShowNewKBase] = useState(null);
    const [showSuccessful, setShowSuccessful] = useState(null);
    const [loadingKBase, setLoadingKBase] = useState(null);
    const [streamAns, setStreamAns] = useState(null);
    const [chat,setChat] = useState([]);
    const [showShare, setShowShare] = useState(false);
    const [showReferral, setShowReferral] = useState(false);
    const [showUpgrade, setShowUpgrade] = useState(false);
    const [authUrl, setAuthUrl] = useState("");
    const [githubAuthUrl, setGithubAuthUrl] = useState("");
    const [stripeTier, setStripeTier] = useState("free");
    const [KBaseError, setKBaseError] = useState("");
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const [yearly, setYearly] = useState(false);
    const [update, setUpdate] = useState(false);
    const [userName, setUserName] = useState("");
    const [question, setQuestion] = useState("");
    const [name, setName] = useState("");
    const [links,setLinks] = useState([""])
    const [files, setFiles] = useState([]);
    const [referrals, setReferrals] = useState({self:'',referral:''});
    const [loadPercent, setLoadPercent] = useState({current:0,total:100});
    const [queries, setQueries] = useState({current:0,limit:50});
    const [removedSources,setRemovedSources] = useState([])
    const [showDeleteConfirmModal,setShowDeleteConfirmModal] = useState(false)
    const [showKBSettingModal,setShowKBSettingModal] = useState(false)
    const [showSimpleLoadingModal,setShowSimpleLoadingModal] = useState(false)
    const [isNotionConnected,setIsNotionConnected] = useState(false)
    const [isShopifyConnected,setIsShopifyConnected] = useState(false)
    const [takeURL, setTakeURL] = useState(false);
    const [storeURL, setStoreURL] = useState("");    
    const [isLeadCaptured,setIsLeadCaptured] = useState(false)
    const [isLeadMessageSent,setisLeadMessageSent] = useState(false)
    const [isLeadNameValidated, setIsLeadNameValidated] = useState(true);
    const [isLeadEmailValidated, setIsLeadEmailValidated] = useState(true);
    const [leadProgress, setLeadProgress] = useState(false);
    const [showIntegrationModal, setShowIntegrationModal] = useState(false);
    const [isNotionDataAdded, setIsNotionDataAdded] = useState(false);
    const [isShopifyDataAdded, setIsShopifyDataAdded] = useState(false);
    const [newLinkPages, setNewLinkPages] = useState([{"index":0,"link_url":"","pages":1}]);
    const chatRef = useRef(null)
    chatRef.current = chat
    const streamRef = useRef(null)
    streamRef.current = streamAns    
    const loadRef = useRef(null)
    loadRef.current = loadPercent

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(()=>{
        if(subscription!=null){
            axios.post("/heybotnew/takeover",{sessId:subscriptionId,enable:0}).then((res)=>{
            }).catch((err)=>{
                console.log(err)
            })
            subscription.unsubscribe()
            setSubscription(null)
            setSubscriptionId(null)
        }
    },[session])   

    let takeOver = () => {
        // if(subscription!=null){subscription.unsubscribe()}
        setSubscription(gen.subscribe(currentKBase.slug+'-'+session.id, ({ data }) => {
            setSubscriptionId(session.id)
            let parseData = JSON.parse(data)
            if(parseData["fromAdmin"]==false){
                setChat([...chatRef.current,{isBot:false,msg:parseData["msg"]}]);
            }
        }))
        axios.post("/heybotnew/takeover",{sessId:session.id,enable:1}).then((res)=>{
            }).catch((err)=>{
            console.log(err)
        })

    }

    let saveKnowledgebase = () => {

        if(isStringValid(name) && (files.length>0 || isStringValid(links.toString()) || isNotionDataAdded || !newLinkPages.some(item =>item.link_url!=="" && item.pages === ""))){
           

            // if( newLinkPages.some(item =>item.link_url!=="" && item.pages === ""  )){
            //     toast.error("Please Enter a valid number of urls to be fetched")
            // }
          
            setLoadingKBase((prev)=>0)
            setSession({id:0,msg:''})
            let size = 0
            files.forEach(file => {
                size = size + Math.round(file.size/1024)
            }
            );
            size = size + (links.length*600)
            setLoadPercent({current:0,total:size})
            let loadTimeout = setInterval(()=>setLoadPercent({current:loadRef.current.current+(Math.random() * (150 - 50) + 50),total:size}),2500)

            const post_data = {files:files,links:links.toString(),removedSources:removedSources.toString(),name:name,id:showNewKBase,addNotionData:isNotionDataAdded}

            if(newLinkPages.some(item =>item.link_url!=="" && item.pages !== "")){
                post_data["link_pages"] = JSON.stringify(newLinkPages)
            }
          

            axios.post("/heybotnew/load_knowledgebase",post_data, {
                headers: {
                'Content-Type': 'multipart/form-data'
                }}).then((res)=>{
                    setLoadingKBase((prev)=>1)
                    setKBases([...KBases,res.data])
                    setCurrentKBase(res.data)
                    clearTimeout(loadTimeout)
                    setTimeout(()=>{setShowNewKBase(null);setLoadingKBase(null)},1500)
                    // toast.success("Knowlegdebase created successfully")

              }).catch((err)=>{
                console.log(err.response.data)
                setLoadingKBase((prev)=>null)
                setShowNewKBase(err.response.data.id)
                setKBaseError(err.response.data.error)
                clearTimeout(loadTimeout)
                if(err.response.data.error!=undefined&&err.response.data.error!=""){
                    toast.success(err.response.data.error)
                }else{
                    toast.success("Error creating Knowlegdebase")

                }
            })
            
        }else{
            toast.error("Please enter valid details to create a knowledgebase.")
        }
  
    }

    
    let cancelSubscription = () => {
        axios.get("/heybotnew/stripe_cancel").then((res)=>{
            setStripeTier("free")
            toast.success("Subscription Cancelled")
            setShowUpgrade(false)
        
            }).catch((err)=>{
                toast.error("Error cancelling subscription, please try again.")
        })
    }
    let saveConvo = (getQuestion,getAnswer) => {
        axios.post("/heybotnew/save_convo",{kbaseId:currentKBase.id,sessId:session.id,question:getQuestion,answer:getAnswer}).then((res)=>{
            if(res.data.id!=session.id){
                setSession(res.data)
            }
        
            }).catch((err)=>{
            console.log(err)
        })
    }

    let createReferral = () => {
        axios.get("/heybotnew/create_referral").then((res)=>{
            setReferrals({self:res.data,referral:referrals.referral})
        
            }).catch((err)=>{
            console.log(err)
        })
    }

    let addReferral = () => {
        axios.post("/heybotnew/add_referral",{referral:referrals.referral}).then((res)=>{
            setShowReferral(false)
        
            }).catch((err)=>{
            console.log(err)
        })
    }

    let loadKnowledgebase = (id) => {
        axios.post("/heybotnew/get_kbase",{id:id}).then((res)=>{
            setLinks(res.data.links)
            setFiles(res.data.files)
            setName(res.data.name)
            setShowNewKBase(id)
            setIsNotionDataAdded(res.data.notion_data_added)
            setIsShopifyDataAdded(res.data.shopify_data_added)
            setNewLinkPages([])
            }).catch((err)=>{
            console.log(err)
        })
    }

    let deleteKnowledgebase = () => {
        axios.post("/heybotnew/delete_kbase",{id:showNewKBase}).then((res)=>{
            setKBases(KBases.filter((kb)=>kb.id!==showNewKBase))
            setCurrentKBase(KBases[0])   
            setShowNewKBase(null)
            setShowDeleteConfirmModal(false)
            toast.success("Knowledgebase deleted successfully.")
            }).catch((err)=>{
            console.log(err)
            toast.error("Error deleting knowledgebase.")
        })
    }

    let checkoutStripe = (tier) => {
        let getTier
        if(yearly==true){
            getTier = "yearly_"+tier
            sendAnalyticsEvent("upgradeyearly"+tier+"_click")
        }else{
            getTier = "monthly_"+tier
            sendAnalyticsEvent("upgrademonthly"+tier+"_click")
        }

        

        axios.get("/heybotnew/create_checkout_session?forPrice="+getTier).then((res)=>{
            window.location.href = res.data
            }).catch((err)=>{
            console.log(err)
        })
    }

    let askKBase = async() => {
        if(question!=""){
            let isBot = false
            if(subscription!=null){
                isBot = true
            }
            if(isLeadMessageSent){
                setChat([...chatRef.current,{isBot:isBot,msg:question}])
            }else{
                setChat([...chatRef.current,{isBot:isBot,msg:question},{isBot:true,msg:"generate-lead"}])

            }
            let getQuestion = question
            setQuestion("")
            var productLinks = []
            //var slug = "vadootv"
            if(subscription==null){
                setStreamAns("")
                //const eventSource = new EventSource('http://localhost:9000/api?question='+getQuestion+'&slug='+currentKBase.slug+'&sessId='+session.id)
                const eventSource = new EventSource('https://embedai.vercel.app/api?question='+getQuestion+'&slug='+currentKBase.slug+'&sessId='+session.id)
                eventSource.onmessage = function (event) {
                    //var out = event.data.replaceAll("--*", "\n")
                    //setStreamAns((prev)=> prev + out)
                    var getData = JSON.parse(event.data)
                    console.log("Data", getData, getData.hasOwnProperty('text'));
                    if(getData.hasOwnProperty('text')){
                        var out = getData.text.replaceAll("--*", "\n")
                        console.log("Data out", out)
                        setStreamAns((prev)=> prev + out)
                    }else{
                        productLinks.push({url:getData.url,title:getData.title,image:getData.image,description:getData.description})
                        // let out = JSON.parse()
                        // console.log(out)
                    }
                }

                eventSource.onerror = () => {
                    if(streamRef.current==""){
                        setChat([...chatRef.current,{isBot:false,msg:"An error occured, please try again"}])
                        setQuestion(getQuestion)
                    }else{
                        let currentStream = streamRef.current
                        setQueries({current:queries.current+1,limit:queries.limit})
                        if(productLinks.length>0){
                            setChat([...chatRef.current,{isBot:true,msg:currentStream,linkPreview:productLinks}]);
                            setStreamAns((prev)=>null);
                            saveConvo(getQuestion,currentStream+" \n "+productLinks.toString())
                        }else{
                            var matches = currentStream.match(/\bhttps?:\/\/\S+/gi);
                            if (matches!=null && matches.length>0){
                                axios.post("/heybotnew/preview_link",{link:matches[0]}).then((res)=>{
                                    setChat([...chatRef.current,{isBot:true,msg:currentStream,linkPreview:[res.data]}]);
                                    setStreamAns((prev)=>null);
                                    saveConvo(getQuestion,currentStream)
                                }).catch((err)=>{
                                    console.log(err)
                                })
                            }else{
                                setChat([...chatRef.current,{isBot:true,msg:currentStream,linkPreview:null}]);
                                setStreamAns((prev)=>null);
                                saveConvo(getQuestion,currentStream)
                            }
                        }
                    }
                    eventSource.close()
                }
            }else{
                let data = `{"msg":"${getQuestion}","fromAdmin":true}`
                await gen.publish(currentKBase.slug+'-'+session.id, data)
            }
        }
    }
    
    let copyEmbed = (type) => {
        let copyCode = `<script id="embedai" src="https://embedai.thesamur.ai/embedai.js" data-id="${currentKBase.slug}"></script>`
        let copyCodeSimple = "https://embedai.thesamur.ai/embedai/embed/"+currentKBase.slug+"?simple=true"
        if(type==0){
            navigator.clipboard.writeText(copyCode)
            toast.success("Embed copied.")
        }else{
            navigator.clipboard.writeText(copyCodeSimple)
            toast.success("Link copied.")
        }
    }

    useEffect(() => {
        if (document.querySelector(".end-chat") != null) {
          document
            .querySelector(".end-chat")
            .scrollIntoView({ behavior: "smooth", block: "end" });
        }

        if (chat) {
            chat.forEach(refItem => {
                console.log(refItem['msg']==="generate-lead")
              if (refItem.hasOwnProperty('msg') && refItem['msg'] === 'generate-lead') {
                setisLeadMessageSent(true)
              } 
            });
          }

      }, [chat]);

    useEffect(() => {
        setSession({id:0,msg:''})
        setIsNotionDataAdded(false)
        setIsShopifyDataAdded(false)
        if(currentKBase!=null){
            if(currentKBase.isNew!=undefined && currentKBase.isNew){
                setChat([{isBot:true,msg:currentKBase.initMsg}])
            }else{
                setChat([{isBot:true,msg:currentKBase.initMsg}])
            }
        }
    },[currentKBase])

    let loadShopify = (shop) => {
        setShowSimpleLoadingModal(true)

        axios.post("/heybotnew/load_shopify",{shop:shop}).then((res)=>{
                setShowSimpleLoadingModal(false)
                setKBases([...KBases,res.data])
                setCurrentKBase(res.data)
            }).catch((err)=>{
                console.log(err)
                toast.error("Error creating Knowlegdebase")

        })
    }

    let confirmPayment = () => {
        axios.get("/heybotnew/verify_payment").then((res)=>{
                setShowSuccessful(res.data.paid)
                setTimeout(()=>setShowSuccessful(null),3000)
                setStripeTier(res.data.tier)
                searchParams.delete('stripe_success')
                setSearchParams(searchParams)
            }).catch((err)=>{
            console.log(err)
        })
    }

    useEffect(() => {
        let adminId = ""
        // if(searchParams.get("forAdmin")!=null){
        //   adminId = searchParams.get("forAdmin")
        // }
        axios.get("/heybotnew/isLoggedIn",{params:{adminId:adminId}}).then((res)=>{
            if(res.data.onboarded==false){
                navigate('/onboarding');
            }
            setIsLoggedIn(res.data.isLoggedIn)
            if(res.data.isLoggedIn == false){
                setAuthUrl(res.data.auth_url)
                setGithubAuthUrl(res.data.github_auth_url)
                setIsLoggedIn((prev)=>false)
            }else{
                setKBases(res.data.knowledgebases)
                if(res.data.knowledgebases[1]){
                    setCurrentKBase(res.data.knowledgebases[1])            
                }else{
                    setCurrentKBase(res.data.knowledgebases[0])   
                }
                setUserName(res.data.userName)
                setQueries({current:res.data.queries,limit:res.data.queryLimit})
                let added = null
                if(res.data.referralAdded==0){
                    added = ""
                }
                setReferrals({self:res.data.referralCode,referral:added})
                if(res.data.stripeTier!=""&&res.data.stripeTier!=null){
                    setStripeTier(res.data.stripeTier)
                }
                if(res.data.notion_connected!=null && res.data.notion_connected){
                    setIsNotionConnected(true)
                }else{
                    setIsNotionConnected(false)
                }
                if(searchParams.get("stripe_success")){
                    confirmPayment()
                }
                if(searchParams.get("integrate_shop")){
                    if(searchParams.get("integrate_shop")=='failed'){
                        toast.error("Error adding Shopify integration")
                    }else{
                        loadShopify(searchParams.get("integrate_shop"))
                    }
                }

            }
            }).catch((err)=>{
            console.log(err)
        })
    }, [])


    const handleCloseEditKBModal = ()=>{
        setShowNewKBase(null)
        setLinks([""])
        setNewLinkPages([{"index":0,"link_url":"","pages":1}])
        setFiles([])
        setName("")
        setLoadingKBase(null)
        setIsNotionDataAdded(false)

    }

    const handleConnectNotion = ()=>{
        window.location.replace("https://api.notion.com/v1/oauth/authorize?client_id=5f17a66a-53f7-42b7-bdcc-21f1cced7673&response_type=code&owner=user&redirect_uri=https%3A%2F%2Fembedai.thesamur.ai%2Fheybotnew%2Fintegrate_notion")
        
    }

    const handleConnectShopify = ()=>{
        if(storeURL!=""){
            axios.post("/heybotnew/get_shopify_link",{shop_url:storeURL}).then((res)=>{
            window.location.replace(res.data)

            }).catch((err)=>{
            console.log(err)
        })}
        // window.location.replace("https://admin.shopify.com/store/testing-embedai/admin/oauth/authorize?client_id=3ce47f96c3680a292272891637cd2fac&scope=read_orders,write_products&redirect_uri=http%3A%2F%2Flocalhost:5000%2Fintegrate_shopify")

    }

    const handleGithubClick = ()=>{
        sendAnalyticsEvent("githublogin_click")
    }

    const handleGoogleClick = ()=>{
        sendAnalyticsEvent("googlelogin_click")
    }

    const handleLeadCaptureClick = (event)=>{
       
        //setIsLeadCaptured(true)
      
        const leadName = document.getElementById("formBasicName").value;
        const leadEmail = document.getElementById("formBasicEmail").value;
        console.log(leadName)
        console.log(leadEmail)
        

        if(!isStringValid(leadName)){
            setIsLeadNameValidated(false)
        }else{
            setIsLeadNameValidated(true)
        }

        if(!isStringValid(leadEmail) || !validateEmail(leadEmail)){
            setIsLeadEmailValidated(false)
           
        }else{
            setIsLeadEmailValidated(true)
        }

        if(isStringValid(leadName) && isStringValid(leadEmail) && validateEmail(leadEmail)){
            setLeadProgress(true)
            setIsLeadEmailValidated(true)
            setIsLeadNameValidated(true)

            axios.post("/heybotnew/save_lead_data",{name:leadName,email:leadEmail,slug:currentKBase.slug}).then((res)=>{
            if(res.data.status==="fail"){
                toast.error("Error saving data.")
                setLeadProgress(false)
            }else{
                toast.success("Data saved successfully.")
                setIsLeadCaptured(true)
                setLeadProgress(false)
            }
            }).catch((err)=>{
            console.log(err)
        })
        }else{
            setLeadProgress(false)
        }
    }

    const handleRemoveNotionIntegration = () => {
        if(!isNotionConnected){
            toast.error("Notion not connected")
            console.error("Notion not connected.")
        }else{
            axios.get("/heybotnew/remove_notion").then((res)=>{
               toast.success("Notion integration removed successfully")
               setIsNotionConnected(false)
                }).catch((err)=>{
                
                toast.error("Error removing Notion integration")
            })
        }
        
        
    }

    const handleRemoveShopifyIntegration = () => {
    }

    const handleAddNotionData = ()=>{
       setIsNotionDataAdded(!isNotionDataAdded)
    }

    const handleNotionFromModalClick = ()=>{
        handleCloseEditKBModal()
        setShowIntegrationModal(true)
    }

    const handleNewLinkAdd=()=>{
        setLinks([...links,""])
         setNewLinkPages([...newLinkPages,{"index":links.length,"link_url":"","pages":1}])
         console.log(newLinkPages)
    }

    const handleLinkChange=(link,index,value)=>{
        links[index]=value;
        setUpdate((prev)=>!prev)
        
        const updatedNewLinkPages = newLinkPages.map(item => {
            if (item.index === index) {
                return { ...item, link_url: value };
            }
            return item;
  });

  setNewLinkPages(updatedNewLinkPages)

    }

    const handleLinkRemove=(link,index)=>{
        setRemovedSources([...removedSources,link]);
        setLinks(links.filter((l)=>l!==link));
        setNewLinkPages(newLinkPages.filter((l)=>l.link_url!==link));
        
    }

    const handleLinkPagesChange= (index,link,page)=>{
        const updatedNewLinkPages = newLinkPages.map(item => {
            if (item.index === index) {
                return { ...item, pages: page===""?"":parseInt(page) };
            }
            return item;
  });

  setNewLinkPages(updatedNewLinkPages)

    }


    return(
        <div className="heybotnew-bg d-flex flex-column p-0 p-lg-3 ">
            {/*<LoadScript url="https://cdn.popupsmart.com/bundle.js" />*/}
            {/* <iframe style="position: fixed; width: 396px; height: 70vh; bottom: 32px; right: 32px; z-index: 999999; transition: opacity 300ms ease-in-out 0s; background: rgb(255, 255, 255);  opacity: 1; " src="/heybotnew/eg" /> */}
            {/* <iframe style={{position: 'fixed', width: '396px', height: '70vh', bottom: '32px', right: '32px', zIndex: 999999, opacity: 1}} src="/heybotnew/uuki-test" /> */}

            <div className="d-flex d-lg-none justify-content-between align-items-center py-1 px-3 p-lg-2">
                <div class="d-flex align-items-center gap-1">
                    <MenuIcon onClick={()=>setShowMenu(true)} className=" heybotnew-image cursor-pointer" />
                    <Image className="heybotnew-logo-image" src={logoImage}/>
                </div>
                <div onClick={()=>setSession({id:0,msg:''})} className={`p-2 d-flex align-items-center heybotnew-new-btn`}>
                    <PlusIcon className="heybotnew-image-small" />
                </div>
            </div>
            <Row className="g-0 flex-grow-1 overflow-hidden">
                <Col className=" d-none d-lg-block heybotnew-nav" lg={3}>
                    <div className={`heybotnew-box ${theme==0?"heybotnew-theme-light":"heybotnew-theme-dark"} p-3 h-100`}>
                        <Navbar userName={userName} theme={theme} KBases={KBases} currentKBase={currentKBase} setCurrentKBase={setCurrentKBase} setShowNewKBase={setShowNewKBase} setShowKBSettingModal={setShowKBSettingModal} setShowShare={setShowShare} setSession={setSession} loadKnowledgebase={loadKnowledgebase} queries={queries} setShowReferral={setShowReferral} setChat={setChat} setShowUpgrade={setShowUpgrade} setShowIntegrationModal={setShowIntegrationModal}/>
                    </div>
                </Col>
                <Col className="ps-lg-3 heybotnew-main" lg={9}>
                {currentKBase!=null&&
                    <div className={`heybotnew-box ${currentKBase.theme[0]=='light'?"heybotnew-theme-light":"heybotnew-theme-dark"} py-3 px-3 px-lg-4 d-flex flex-column align-items-center h-100`}>
                        <div className="d-flex flex-column gap-4 heybotnew-scroll heybotnew-chat-section flex-grow-1 w-100 position-relative" style={{flex:'1 1 0'}}>
                            {chat.length>0&&chat.map((msg)=>
                               
                                <>
                                    {msg.msg!="generate-lead"?
                                    <>
                                    <div style={((subscription==null&&msg.isBot==true)||(subscription!=null&&msg.isBot==false))?{background:currentKBase.theme[1],color:currentKBase.theme[2],borderRadius:'1rem',borderTopLeftRadius:0,borderTopRightRadius:'1rem'}:{background:currentKBase.theme[3],color:currentKBase.theme[4],borderRadius:'1rem',borderTopRightRadius:0,borderTopLeftRadius:'1rem'}} className={`${((subscription==null&&msg.isBot==true)||(subscription!=null&&msg.isBot==false)) ? "align-self-start" :"align-self-end"} heybotnew-msg-container`}>
                                        <div class="d-flex flex-wrap gap-2">
                                            {(msg.linkPreview!=null&&msg.linkPreview.length>0)&&msg.linkPreview.map((link)=>
                                                <Card className="heybotnew-preview-card mb-2">
                                                    <a className="text-dark text-decoration-none" target="_blank" href={link.url}>
                                                        <Card.Img className="heybotnew-preview-image" variant="top" src={link.image} />
                                                        <Card.Body className="p-2">
                                                            <Card.Title className="heybotnew-preview-title">{link.title}</Card.Title>
                                                            <Card.Text className="heybotnew-preview-desc">
                                                                {link.description}
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </a>
                                                </Card>
                                            )}
                                        </div>
                                        <div className="heybotnew-answer "><ReactMarkdown  children={msg.msg}   remarkPlugins={[remarkGfm]} ></ReactMarkdown></div>
                                </div></>:<>{ currentKBase.lead_generation_enabled===1 && isLeadCaptured===false?<>
                                <div style={{background:currentKBase.theme[1],color:currentKBase.theme[2],borderRadius:'1rem',borderTopLeftRadius:0,minWidth:"400px"}} className={`align-self-start heybotnew-msg-container`}>
                                <div className="mb-4 d-flex justify-content-between">
                                <div><b>How to contact you?</b></div>
                                <div onClick={()=>setIsLeadCaptured(true)}>X</div>
                                </div>
                                <Form >
                                <Form.Group className="mb-3" controlId="formBasicName">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Name"  required/>
                                  {isLeadNameValidated?"":<span style={{fontSize:"13px",color:"red"}}>Enter a valid name</span>}
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" placeholder="Enter email"  required/>
                                   
                                    {isLeadEmailValidated?"":<span style={{fontSize:"13px",color:"red"}}>Enter a valid email</span>}
                                    
                                </Form.Group>
                                
                                <div className="d-flex align-items-end justify-content-end">
                                <Button id="leadSubmitBtn" onClick={(e)=>handleLeadCaptureClick(e)} disabled={leadProgress} className={`p-2  mt-2 me-2 heybotnew-button heybotnew-send-btn border-0 ${currentKBase.theme[0]=='dark'?"heybotnew-theme-light":"heybotnew-theme-dark"}`} variant="primary">
                                    <svg className="heybotnew-image" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id="send-alt" class="icon glyph" fill={currentKBase.theme[0]=='light'?"#FFF0EE":"#362f2e"}>
                                        <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                                        <g id="SVGRepo_iconCarrier">
                                        <path d="M21.88,4.73,16.2,20.65A2,2,0,0,1,14.3,22h0a2,2,0,0,1-1.9-1.31l-2.12-5.52,1.54-1.54,2.49-2.49a1,1,0,1,0-1.42-1.42l-2.49,2.49L8.82,13.76,3.31,11.63a2,2,0,0,1,0-3.83L19.27,2.12a2,2,0,0,1,2.61,2.61Z" style={currentKBase.theme[0]=='light'?{fill:'#FFF0EE'}:{fill:'#362f2e'}}/>
                                        </g>
                                    </svg>
                                </Button>
                                </div>
                                </Form>
                                </div>
                                
                            </>:""}</>}
                                </>
                                
                            )}
                            {streamAns!=null&&<div style={{background:currentKBase.theme[1],color:currentKBase.theme[2],borderRadius:'1rem',borderTopLeftRadius:0}} className={`align-self-start heybotnew-msg-container`}>
                                <div className="heybotnew-answer">{streamAns==""?<span  className="blinking-cursor"> █</span>:<ReactMarkdown className="answer-typing" children={streamAns} remarkPlugins={[remarkGfm]} />}</div>
                            </div>}
                            
                            {(session.isLive==1&&subscription==null)&&<div className="position-absolute bottom-0 end-0 mb-3 me-3 pe-lg-5 me-lg-5">
                                <Button  className="share-button px-3 p-2" onClick={takeOver}>
                                    Take Over
                                </Button>
                            </div>}
                            
                            <div className="end-chat mt-auto"></div>

                        </div>
                        <div class="d-flex flex-column heybotnew-input-container overflow-hidden w-100 pt-2 gap-2">
                            <div class="d-flex suggestion-scroll overflow-auto gap-1">{currentKBase.suggestions.map((suggestion)=><>
                                {suggestion!=""?<div onClick={()=>setQuestion(suggestion)} style={{background:currentKBase.theme[1],color:currentKBase.theme[2]}} className={`border text-nowrap heybotnew-answer heybotnew-suggestion`}>{suggestion}</div>:""}
                            </>)}</div>
                            <div class="d-flex position-relative">
                                <FormControl onKeyDown={(e) => {
                                e.code == "Enter" && !e.shiftKey && askKBase();
                            }} value={question} onChange={(e)=>setQuestion(e.target.value)} className={`px-3 pe-5 py-2 heybotnew-input ${currentKBase.theme[0]=='light'?"theme-light":"theme-dark"}`} placeholder="Ask a question"/>
                                <Button onClick={askKBase} className={`p-2 position-absolute end-0 mt-2 me-2 heybotnew-button heybotnew-send-btn border-0 ${currentKBase.theme[0]=='dark'?"heybotnew-theme-light":"heybotnew-theme-dark"}`} variant="primary">
                                    <svg className="heybotnew-image" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id="send-alt" class="icon glyph" fill={currentKBase.theme[0]=='light'?"#FFF0EE":"#362f2e"}>
                                        <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                                        <g id="SVGRepo_iconCarrier">
                                        <path d="M21.88,4.73,16.2,20.65A2,2,0,0,1,14.3,22h0a2,2,0,0,1-1.9-1.31l-2.12-5.52,1.54-1.54,2.49-2.49a1,1,0,1,0-1.42-1.42l-2.49,2.49L8.82,13.76,3.31,11.63a2,2,0,0,1,0-3.83L19.27,2.12a2,2,0,0,1,2.61,2.61Z" style={currentKBase.theme[0]=='light'?{fill:'#FFF0EE'}:{fill:'#362f2e'}}/>
                                        </g>
                                    </svg>
                                </Button>
                            </div>
                        </div>
                    </div>
                }
                </Col>
            </Row>
            <Offcanvas show={showMenu} onHide={()=>setShowMenu(false)} placement="start" className="heybotnew-offcanvas">
                <Offcanvas.Body className="p-0">
                    <div className={`heybotnew-box ${theme==0?"heybotnew-theme-light":"heybotnew-theme-dark"} py-3 px-4 vh-100 d-flex flex-column gap-2`}>
                        <h3 className="align-self-end cursor-pointer fw-bold" onClick={()=>setShowMenu(false)}>x</h3>
                        <Navbar userName={userName} theme={theme} KBases={KBases} currentKBase={currentKBase} setCurrentKBase={setCurrentKBase} setShowNewKBase={setShowNewKBase} setShowKBSettingModal={setShowKBSettingModal} setShowShare={setShowShare} setSession={setSession} loadKnowledgebase={loadKnowledgebase} queries={queries} setShowReferral={setShowReferral} setChat={setChat} setShowUpgrade={setShowUpgrade} setShowIntegrationModal={setShowIntegrationModal}/>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
            <ToastContainer/>
            <Modal  className={` ${theme==0?"theme-light":"theme-dark"} heybotnew-modal`} centered backdrop="static" show={showNewKBase!=null && showKBSettingModal==true} onHide={()=>setShowNewKBase(null)}>
                {loadingKBase!=null?
                    <div className="d-flex flex-column align-items-center justify-content-center vh-100">
                       {loadingKBase==0?
                            <>
                            <svg className="" id="kbase-loader">
                                <g>
                                    <path d="M 50,100 A 1,1 0 0 1 50,0"/>
                                </g>
                                <g>
                                    <path d="M 50,75 A 1,1 0 0 0 50,-25"/>
                                </g>
                                <defs>
                                    <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                                    <stop offset="0%" style={{stopColor:'#DCF6FF',stopOpacity:1}} />
                                    <stop offset="100%" style={{stopColor:'#ABE4F8',stopOpacity:1}} />
                                    </linearGradient>
                                </defs>
                            </svg>
                            <ProgressBar className="w-75 mt-5 heybotnew-loader" now={(loadRef.current.current/loadPercent.total)*100}/>
                            <h4 className="fw-bold py-4">Creating your Knowledgebase</h4>
                        </>:<>
                            <svg style={{width:'5rem'}} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <rect width="24" height="24" fill="white"></rect> <path fill-rule="evenodd" clip-rule="evenodd" d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM15.7071 9.29289C16.0976 9.68342 16.0976 10.3166 15.7071 10.7071L12.0243 14.3899C11.4586 14.9556 10.5414 14.9556 9.97568 14.3899L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929C8.68342 10.9024 9.31658 10.9024 9.70711 11.2929L11 12.5858L14.2929 9.29289C14.6834 8.90237 15.3166 8.90237 15.7071 9.29289Z" fill="#00bd48"></path> </g>
                            </svg>
                            <h4 className="fw-bold py-4">Chatbot Trained!</h4>
                        </>}
                    </div>
                :
                    <>
                        <Modal.Header className="px-3 px-lg-5 py-3 py-lg-4 position-relative">
                            <h4 className="m-0">{showNewKBase==0?"New":"Edit"} Knowledgebase</h4>
                            <h4 style={{cursor:'pointer'}} onClick={()=>handleCloseEditKBModal()} className="position-absolute fw-bold top-0 end-0 me-3 me-lg-5 mt-3 mt-lg-4">x</h4>
                        </Modal.Header>
                        <Modal.Body className="px-3 px-lg-5 py-3 py-lg-4  overflow-hidden h-75">
                            {KBaseError!==""&&<div className="w-100 px-3 py-1 mb-3 error-msg text-center"><small>{KBaseError}</small></div>}
                            <Stack className="heybotnew-scroll h-100 pb-4" gap={3}>
                            <span>Name your knowledgebase chatbot </span>
                            <FloatingLabel
                                className="w-100"
                                label="Knowledgebase Name"
                            >
                                <Form.Control value={name} onChange={(e)=>setName(e.target.value)} placeholder="Knowledgebase Name" className={` px-lg-3 heybotnew-input ${theme==0?"theme-light":"theme-dark"}`}/>
                            </FloatingLabel>
                            {isShopifyDataAdded&&<>
                            <div className="divider-horizontal"></div>
                            <div className="d-flex align-items-center gap-2">
                                <ShopifyIcon style={{height:"40px",width:"40px"}}></ShopifyIcon>
                                Shopify Data Added!

                            </div>
                            </>}
                            <div className="heybotnew-divider-horizontal"></div>
                            <span> Add web or youtube links to use </span>
                            {links.map((link,index)=><>
                                <Stack direction="horizontal" gap={3}>
                                <FloatingLabel
                                className="w-100 position-relative"
                                label="Link URL"
                            >
                                <b className="position-absolute top-0 end-0 me-2 cursor-pointer" onClick={()=>{handleLinkRemove(link,index)}}>x</b>
                                <Form.Control value={links[index]} onChange={(e)=>{handleLinkChange(link,index,e.target.value)}} placeholder="Knowledgebase Name" className={` px-lg-3 heybotnew-input ${theme==0?"theme-light":"theme-dark"}`}/>
                            </FloatingLabel>
                            
                            {(newLinkPages.some(item => item.link_url === link) || link==="")?<div>
                            <Form.Control type="number" placeholder="1" min="1" data-bs-toggle="tooltip" data-bs-placement="top"  defaultValue={1} data-bs-title="Enter the number of pages to scrape." onChange={(e)=>handleLinkPagesChange(index,link,e.target.value)} className={`heybotnew-input ${theme==0?"theme-light":"theme-dark"}`}/>
                            </div>:""}
                                </Stack>
                            </>)}
                            <Button onClick={()=>handleNewLinkAdd()} className={`p-2 heybotnew-general-btn heybotnew-button border-0 ${theme==1?"heybotnew-theme-light":"heybotnew-theme-dark"}`} >
                                + Add another Link
                            </Button>
                            <div className="heybotnew-divider-horizontal"></div>
                            <div>
                            <Form.Check type="switch" checked={isNotionDataAdded} label="Add notion data" onChange={()=>{handleAddNotionData()}}/>
                            </div>
                           
                            <div class="d-flex gap-2">
                                {files.length>0&&files.map((file)=><small className="px-2 py-1 bg-light text-dark border rounded">
                                    {file.name}
                                    <b className="cursor-pointer ms-1" onClick={()=>{setRemovedSources([...removedSources,file.name]);setFiles(files.filter((f)=>f.name!==file.name))}}>x</b>
                                </small>)}
                            </div>
                            <FileUploader className="heybotnew-file-uploader" handleChange={(file)=>setFiles([...files,file])} name="file" types={["PDF"]} />
                            {/* <Button onClick={()=>handleConnectNotion()} className={`p-2 heybotnew-general-btn heybotnew-button border-0 ${theme==1?"heybotnew-theme-light":"heybotnew-theme-dark"}`} >
                                Connect Notion
                            </Button> */}
                            {showNewKBase!==0&&<Button className=" bg-danger heybotnew-share-button heybotnew-general-btn p-2" onClick={()=>{
                                setShowKBSettingModal(false)
                                setShowDeleteConfirmModal(true)}}>
                                Delete Knowledgebase
                            </Button>}
                            </Stack>
                        </Modal.Body>
                        <Modal.Footer className="px-3 px-lg-5 py-3 py-lg-4 d-flex justify-content-center">
                            <Button className="heybotnew-share-button heybotnew-general-btn p-2" onClick={saveKnowledgebase}>
                                Save
                            </Button>
                        </Modal.Footer>
                    </>}
            </Modal>    
            <Modal  className={` ${theme==0?"theme-light":"theme-dark"} heybotnew-modal`} centered show={showShare} onHide={()=>setShowShare(false)}>
                <Modal.Header className="px-3 px-lg-5 py-3 py-lg-4 position-relative">
                    <h4 className="m-0">Share your chatbot</h4>
                    <h4 style={{cursor:'pointer'}} onClick={()=>setShowShare(false)} className="position-absolute fw-bold top-0 end-0 me-3 me-lg-5 mt-3 mt-lg-4">x</h4>
                </Modal.Header>
                <Modal.Body className="px-3 px-lg-5 py-3 py-lg-4 h-100 ">
                    <Stack className="justify-content-center align-items-center h-75 py-5 py-lg-0" gap={5}>
                        <div className="heybotnew-primary-btn py-4  d-flex justify-content-center" onClick={()=>copyEmbed(0)}>
                            <EmbedIcon className="heybotnew-image me-2" />
                            Copy Embed
                        </div>
                        <div className="d-flex gap-2 align-items-center">
                            <div className="heybotnew-divider-horizontal"></div>
                            <small>or</small>
                            <div className="heybotnew-divider-horizontal"></div>
                        </div>
                        <div className="heybotnew-primary-btn py-4 d-flex justify-content-center" onClick={()=>copyEmbed(1)}>
                            <LinkIcon className="heybotnew-image me-2" />
                            Get Link
                        </div>

                      
                    </Stack>
                </Modal.Body>
               
            </Modal> 
            <Modal  className={` ${theme==0?"theme-light":"theme-dark"} heybotnew-modal`} centered show={showReferral} onHide={()=>setShowReferral(false)}>
                <Modal.Header className="px-3 px-lg-5 py-3 py-lg-4 position-relative">
                    <h4 className="m-0">Referrals</h4>
                    <h4 style={{cursor:'pointer'}} onClick={()=>setShowReferral(false)} className="position-absolute fw-bold top-0 end-0 me-3 me-lg-5 mt-3 mt-lg-4">x</h4>
                </Modal.Header>
                <Modal.Body className="px-3 px-lg-5 py-3 py-lg-4 h-100 ">
                    <Stack className="justify-content-center align-items-center h-75 py-5 py-lg-0" gap={5}>

                        { referrals.referral!==null&&
                        <>
                            <div className="w-100 text-center">
                                <small className="fw-bold">Add a referral</small>
                            
                                <div className="d-flex gap-2 w-100">
                                    <Form.Control value={referrals.referral} onChange={(e)=>setReferrals({self:referrals.self,referral:e.target.value})} placeholder="Add referral code" className={` px-lg-3 heybotnew-input ${theme==0?"theme-light":"theme-dark"}`}/>
                                    <Button style={{width:'5rem'}} className="heybotnew-share-button p-2" onClick={addReferral}>
                                    Add
                                    </Button>
                                </div>
                            </div>
                            <div className="d-flex gap-2 align-items-center">
                                <div className="heybotnew-divider-horizontal"></div>
                                <small>or</small>
                                <div className="heybotnew-divider-horizontal"></div>
                            </div>
                        </>
                        }
                        <div className="w-100 text-center">
                            <small className="fw-bold">Share your referral and get free queries</small>
                            <div className="d-flex gap-2 w-100">
                                <Form.Control value={referrals.self} placeholder="Add referral code" className={` px-lg-3 heybotnew-input ${theme==0?"theme-light":"theme-dark"}`}/>
                                {(referrals.self!==""&&referrals.self!==null)?
                                 <Button style={{width:'5rem'}} className="heybotnew-share-button p-2" onClick={()=>navigator.clipboard.writeText(referrals.self)}>
                                 Copy
                                 </Button>:
                                <Button style={{width:'5rem'}} className="heybotnew-share-button p-2" onClick={createReferral}>
                                Create
                                </Button>
                               }
                            </div>
                        </div>

                      
                    </Stack>
                </Modal.Body>
               
            </Modal> 
            <Modal  className={` ${theme==0?"theme-light":"theme-dark"} heybotnew-modal`} centered show={!isLoggedIn}>
                <Modal.Body className="px-3 px-lg-5 py-3 py-lg-4 h-100 ">
                    <Stack className="justify-content-center align-items-center h-75 py-5 py-lg-0" gap={3}>
                        <Image src={logoImage} className="heybotnew-logo-image" />

                        <h4 className="m-0">Welcome to <b>EmbedAI</b></h4>
                        <small>Login to continue</small>
                        <Button className="heybotnew-primary-btn d-flex align-items-center justify-content-center w-50 py-4" onClick={()=>handleGoogleClick()} href={authUrl}>
                          <GoogleIcon className="icon me-2 p-1 bg-light rounded-circle" /> Login with
                          Google
                        </Button>
                        <Button className="heybotnew-primary-btn d-flex align-items-center justify-content-center w-50 py-4" onClick={()=>handleGithubClick()} href={githubAuthUrl}>
                          <GithubIcon className="icon me-2 p-1 bg-light rounded-circle" /> Login with
                          Github
                        </Button>
                 
                    </Stack>
                </Modal.Body>
               
            </Modal>    

            <Modal  className={` ${theme==0?"theme-light":"theme-dark"} pricing-modal`} centered show={showUpgrade} onHide={()=>setShowUpgrade(false)}>
                <Modal.Header className="px-3 px-lg-5 py-3 py-lg-4 position-relative">
                    <h4 className="m-0">Upgrade</h4>
                    <h4 style={{cursor:'pointer'}} onClick={()=>setShowUpgrade(false)} className="position-absolute fw-bold top-0 end-0 me-3 me-lg-5 mt-3 mt-lg-4">x</h4>
                </Modal.Header>
                <Modal.Body className="px-3 px-lg-5 py-3 py-lg-4 h-100 d-flex flex-column align-items-center justify-content-center">
                    <ButtonGroup>
                        <ToggleButton
                            id={`radio-1`}
                            type="radio"
                            name="radio"
                            variant="outline-dark"
                            className="heybotnew-pricing-toggle px-3"
                            checked={!yearly}
                            onChange={(e) => setYearly(false)}
                        >
                            Monthly
                        </ToggleButton>
                        <ToggleButton
                            id={`radio-2`}
                            type="radio"
                            name="radio"
                            variant="outline-dark"
                            className="heybotnew-pricing-toggle px-3"
                            checked={yearly}
                            onChange={(e) => setYearly(true)}
                        >
                            Yearly
                        </ToggleButton>
                    </ButtonGroup>
                    <Stack direction="horizontal" className="justify-content-center align-items-center w-100 h-100 flex-wrap" gap={3}>
                        <div className="heybotnew-pricing-box d-flex flex-column p-3">
                            <h5 className="fw-bold mb-0">Tier 1</h5>
                            <hr className="my-2"/>
                            <Stack  gap={2}>
                                <div className="d-flex gap-2">
                                    <CheckIcon className="heybotnew-image me-2" />
                                    <small>2,000 message credits/month</small>
                                </div>
                                <div className="d-flex gap-2">
                                    <CheckIcon className="heybotnew-image me-2" />
                                    <small>5 chatbots</small>
                                </div>
                                <div className="d-flex gap-2">
                                    <CheckIcon className="heybotnew-image me-2" />
                                    <small>2,000,000 characters/chatbot</small>
                                </div>
                                <div className="d-flex gap-2">
                                    <CheckIcon className="heybotnew-image me-2" />
                                    <small>Embed on unlimited websites</small>
                                </div>
                                <div className="d-flex gap-2">
                                    <CheckIcon className="heybotnew-image me-2" />
                                    <small>Upload multiple files</small>
                                </div>
                                <div className="d-flex gap-2">
                                    <CheckIcon className="heybotnew-image me-2" />
                                    <small>API Access</small>
                                </div>
                            </Stack>
                            {stripeTier==(yearly?"yearly":"monthly"+"_19")?<small className="fw-bold text-danger cursor-pointer" onClick={cancelSubscription}>Cancel</small>:<>
                                <h4 className="fw-bold mt-auto">{yearly?"$190/year":"$19/month"}</h4>
                                <div onClick={()=>checkoutStripe("19")} className="heybotnew-primary-btn">
                                    <UpgradeIcon className="heybotnew-image-small me-2" />
                                    Upgrade
                                </div>
                            </>}
                        </div>
                        <div className="heybotnew-pricing-box d-flex flex-column p-3">
                            <h5 className="fw-bold mb-0">Tier 2</h5>
                            <hr className="my-2"/>
                            <Stack className="" gap={2}>
                                <div className="d-flex gap-2">
                                    <CheckIcon className="heybotnew-image me-2" />
                                    <small>5,000 message credits/month</small>
                                </div>
                                <div className="d-flex gap-2">
                                    <CheckIcon className="heybotnew-image me-2" />
                                    <small>10 chatbots</small>
                                </div>
                                <div className="d-flex gap-2">
                                    <CheckIcon className="heybotnew-image me-2" />
                                    <small>4,000,000 characters/chatbot</small>
                                </div>
                                <div className="d-flex gap-2">
                                    <CheckIcon className="heybotnew-image me-2" />
                                    <small>Embed on unlimited websites</small>
                                </div>
                                <div className="d-flex gap-2">
                                    <CheckIcon className="heybotnew-image me-2" />
                                    <small>Upload multiple files</small>
                                </div>
                                <div className="d-flex gap-2">
                                    <CheckIcon className="heybotnew-image me-2" />
                                    <small>API Access</small>
                                </div>
                            </Stack>
                            {stripeTier==(yearly?"yearly":"monthly"+"_49")?<small className="fw-bold text-danger cursor-pointer" onClick={cancelSubscription}>Cancel</small>:<>
                                <h4 className="fw-bold mt-auto">{yearly?"$490/year":"$49/month"}</h4>
                                <div onClick={()=>checkoutStripe("49")} className="heybotnew-primary-btn">
                                    <UpgradeIcon className="heybotnew-image-small me-2" />
                                    Upgrade
                                </div>
                            </>}
                        </div>
                        <div className="heybotnew-pricing-box d-flex flex-column p-3">
                            <h5 className="fw-bold mb-0">Tier 3</h5>
                            <hr className="my-2"/>
                            <Stack className="" gap={2}>
                                <div className="d-flex gap-2">
                                    <CheckIcon className="heybotnew-image me-2" />
                                    <small>10,000 message credits/month</small>
                                </div>
                                <div className="d-flex gap-2">
                                    <CheckIcon className="heybotnew-image me-2" />
                                    <small>20 chatbots</small>
                                </div>
                                <div className="d-flex gap-2">
                                    <CheckIcon className="heybotnew-image me-2" />
                                    <small>6,000,000 characters/chatbot</small>
                                </div>
                                <div className="d-flex gap-2">
                                    <CheckIcon className="heybotnew-image me-2" />
                                    <small>Embed on unlimited websites</small>
                                </div>
                                <div className="d-flex gap-2">
                                    <CheckIcon className="heybotnew-image me-2" />
                                    <small>Upload multiple files</small>
                                </div>
                                <div className="d-flex gap-2">
                                    <CheckIcon className="heybotnew-image me-2" />
                                    <small>API Access</small>
                                </div>                                
                                <div className="d-flex gap-2">
                                    <CheckIcon className="heybotnew-image me-2" />
                                    <small>Option to opt for GPT-4</small>
                                </div>
                            </Stack>
                            {stripeTier==(yearly?"yearly":"monthly"+"_99")?<small className="fw-bold text-danger cursor-pointer" onClick={cancelSubscription}>Cancel</small>:<>
                                <h4 className="fw-bold mt-auto">{yearly?"$990/year":"$99/month"}</h4>
                                <div onClick={()=>checkoutStripe("99")} className="heybotnew-primary-btn">
                                    <UpgradeIcon className="heybotnew-image-small me-2" />
                                    Upgrade
                                </div>
                            </>}
                        </div>
                        {/*
                        <div className="heybotnew-pricing-box d-flex flex-column p-3">
                            <h5 className="fw-bold mb-0">Tier 4</h5>
                            <hr className="my-2"/>
                            <Stack className="" gap={2}>
                                <div className="d-flex gap-2">
                                    <CheckIcon className="heybotnew-image me-2" />
                                    <small>40,000 message credits/month</small>
                                </div>
                                <div className="d-flex gap-2">
                                    <CheckIcon className="heybotnew-image me-2" />
                                    <small>40 chatbots</small>
                                </div>
                                <div className="d-flex gap-2">
                                    <CheckIcon className="heybotnew-image me-2" />
                                    <small>11,000,000 characters/chatbot</small>
                                </div>
                                <div className="d-flex gap-2">
                                    <CheckIcon className="heybotnew-image me-2" />
                                    <small>Embed on unlimited websites</small>
                                </div>
                                <div className="d-flex gap-2">
                                    <CheckIcon className="heybotnew-image me-2" />
                                    <small>Upload multiple files</small>
                                </div>
                                <div className="d-flex gap-2">
                                    <CheckIcon className="heybotnew-image me-2" />
                                    <small>API Access</small>
                                </div>                                
                                <div className="d-flex gap-2">
                                    <CheckIcon className="heybotnew-image me-2" />
                                    <small>Option to opt for GPT-4</small>
                                </div>
                                <div className="d-flex gap-2">
                                    <CheckIcon className="heybotnew-image me-2" />
                                    <small>Remove EmbedAI watermark</small>
                                </div>
                            </Stack>
                            {stripeTier==(yearly?"yearly":"monthly"+"_399")?<small className="fw-bold text-danger cursor-pointer" onClick={cancelSubscription}>Cancel</small>:<>
                                <h4 className="fw-bold mt-auto">{yearly?"$3990/year":"$399/month"}</h4>
                                <div onClick={()=>checkoutStripe("399")} className="heybotnew-primary-btn">                                    
                                    <UpgradeIcon className="heybotnew-image-small me-2" />
                                    Upgrade
                                </div>
                            </>}
                        </div>
                        */}
                    </Stack>
                </Modal.Body>

            </Modal> 
            <Modal  className={` ${theme==0?"theme-light":"theme-dark"} heybotnew-modal`} centered show={showSuccessful!=null} onHide={()=>setShowSuccessful(null)}>
                <Modal.Body className="px-3 px-lg-5 py-3 py-lg-4 h-100 d-flex flex-column align-items-center justify-content-center">
                {showSuccessful==true?
                <>
                    <svg className="heybotnew-success-animation" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                        <circle class="heybotnew-success-path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                        <polyline class="heybotnew-success-path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
                    </svg>
                    <p class="heybotnew-success-answer">Payment Successful</p>
                </>
                :
                <>
                    <svg className="heybotnew-success-animation" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                        <circle class="heybotnew-success-path circle" fill="none" stroke="#D06079" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                        <line class="heybotnew-success-path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3"/>
                        <line class="heybotnew-success-path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2"/>
                    </svg>
                    <p class="heybotnew-error-answer">Payment Failed</p>
                </>
                }
                </Modal.Body>

            </Modal> 

            <Modal  className={` ${theme==0?"theme-light":"theme-dark"}`} centered show={showDeleteConfirmModal} onHide={()=>setShowDeleteConfirmModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Knowledgebase</Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-3 px-lg-5 py-3 py-lg-4 h-100 ">
                    <p>Are you sure, you want to delete the knowledgebase?</p>
                </Modal.Body>
               <Modal.Footer>
               <Stack direction="horizontal" gap={3}>
               <div className="heybotnew-secondary-btn  w-100 p-4"  onClick={(e)=>setShowDeleteConfirmModal(false)}>No</div>
               <div className="heybotnew-primary-btn  w-100 p-4" onClick={(e)=>deleteKnowledgebase()}>Yes</div>
               </Stack>
               
               </Modal.Footer>
            </Modal>                  

            <Modal  className={`small-modal`} centered show={showIntegrationModal} onHide={()=>setShowIntegrationModal(false)}>
                <div className={` ${theme==0?"theme-light":"theme-dark"} rounded-border`}>
                    <Modal.Header closeButton>
                        <Modal.Title>Integrations</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="px-3 px-lg-5 py-3 py-lg-4 h-100">
                       {takeURL?
                       <Stack gap={2}>
                        <span>Add your Shopify Store URL </span>
                            <FloatingLabel
                                className="w-100"
                                label="Shopify store URL"
                            >
                                <Form.Control value={storeURL} onChange={(e)=>setStoreURL(e.target.value)} placeholder="Shopify store URL" className={` px-lg-3 input ${theme==0?"theme-light":"theme-dark"}`}/>
                            </FloatingLabel>
                            <Button  className="share-button p-2" onClick={handleConnectShopify}>
                                Connect Store
                            </Button>
                       </Stack>
                       :<Stack direction="horizontal" gap={3} className="mb-5">
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <div className="integrations-icon-bg" onClick={handleConnectNotion}>
                                {isNotionConnected?<div className="d-flex justify-content-end align-items-end">
                                <IntegrationCheckIcon style={{height:"20px",width:"20px"}}/>
                                </div>:<div style={{minHeight:"20px"}}></div>}
                                <NotionIcon className="mx-4 mb-4 " style={{height:"30px",width:"30px"}}></NotionIcon>
                                </div>
                                <small>Notion</small>
                                {isNotionConnected? <span className="mt-2 integration-remove-text" onClick={()=>handleRemoveNotionIntegration()}>Remove</span>:""}
                            </div>
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <div className="integrations-icon-bg" onClick={()=>setTakeURL(true)}>
                                {isShopifyConnected?<div className="d-flex justify-content-end align-items-end">
                                <IntegrationCheckIcon style={{height:"20px",width:"20px"}}/>
                                </div>:<div style={{minHeight:"20px"}}></div>}
                                <ShopifyIcon className="mx-4 mb-4 " style={{height:"40px",width:"40px"}}></ShopifyIcon>
                                </div>
                                <small>Shopify</small>
                                {isShopifyConnected? <span className="mt-2 integration-remove-text" onClick={handleRemoveShopifyIntegration}>Remove</span>:""}
                            </div>
                       </Stack>}
                    </Modal.Body>
                </div>

            </Modal>  

            <Modal  className={`primary-modal`} centered backdrop="static" show={showSimpleLoadingModal} onHide={()=>setShowSimpleLoadingModal(false)}>
                <div className={`${theme==0?"theme-light":"theme-dark"} rounded-border d-flex flex-column align-items-center justify-content-center vh-100`}>
                        <svg className="" id="kbase-loader">
                            <g>
                                <path d="M 50,100 A 1,1 0 0 1 50,0"/>
                            </g>
                            <g>
                                <path d="M 50,75 A 1,1 0 0 0 50,-25"/>
                            </g>
                            <defs>
                                <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                                <stop offset="0%" style={{stopColor:'#DCF6FF',stopOpacity:1}} />
                                <stop offset="100%" style={{stopColor:'#ABE4F8',stopOpacity:1}} />
                                </linearGradient>
                            </defs>
                        </svg>
                        <h4 className="fw-bold py-4">Creating your Knowledgebase</h4>
                </div>
            </Modal>
                  
        </div>
    )

}
export default HeyBotNew;
