import logo from './logo.svg';
import { BrowserRouter, Route, Routes,Navigate } from 'react-router-dom';
import Home from './pages/Home';
import "./styles/home.css"
import ConversationTest from "./pages/ConversationTest";
import Chatbot from "./pages/Chatbot";
import LoginSignup from "./pages/LoginSignup";
import AppsumoSignup from "./pages/AppsumoSignup";
import { useEffect,useState } from "react";
import axios from 'axios'
import FAQ from "./pages/FAQ";
import uukiLogo from './assets/small-uuki.png'
import {Modal,Stack} from 'react-bootstrap'
import { ReactComponent as DiscordIcon } from "./assets/discord.svg";
import { ReactComponent as DiscordBlueIcon } from "./assets/discord-blue.svg";
import HeyBot from "./pages/HeyBot";
import BotEmbed from "./pages/BotEmbed";
import HeybotFAQ from "./pages/HeybotFAQ";
import ChatGPTClone from "./pages/ChatGPTClone";
import MyGPTFAQ from "./pages/MyGPTFAQ";
import OneSignal from 'react-onesignal';
import RiteBot from "./pages/RiteBot";
import MemeJourney from "./pages/MemeJourney";
import RiteBotFAQ from "./pages/RiteBotFAQ";
import MemeJourneyFAQ from "./pages/MemeJourneyFAQ";
import Plugins from "./pages/Plugins";
import DevPlugins from "./pages/DevPlugins";
import ChatShare from "./pages/ChatShare";
import LanguageGPT from "./pages/LanguageGPT";
import AGI from "./pages/AGI";
import AgentConvo from "./pages/AgentConvo";
import AgentConvoShare from "./pages/AgentConvoShare";
import BabyAGIFrontend from "./pages/BabyAGI";
import AutoGPTPlugins from "./pages/AutoGPTPlugins";
import PrivateGPT from "./pages/PrivateGPT";
import CSVAgent from "./pages/CSVAgent";
import ChatYoutube from "./pages/ChatYoutube";
import ChatPDF from "./pages/ChatPDF";
import HeyBotNew from "./pages/HeyBotNew";
import HeyBotNewEmbed from "./pages/HeyBotNewEmbed";
import HeyBotNewCustomize from "./pages/HeyBotNewCustomize";
import EmbedAIWelcome from "./pages/EmbedAIWelcome";
import EmbedAIBotSelectCrisp from "./pages/EmbedAIBotSelectCrisp";
import EmbedAIBotSelectIntercom from "./pages/EmbedAIBotSelectIntercom";
import PDFtoChatbot from "./pages/PDFtoChatbot";
import NotiontoChatbot from "./pages/NotiontoChatbot";
import ShopifytoChatbot from "./pages/ShopifytoChatbot";
import WordpressAIChatbot from "./pages/WordpressAIChatbot";
import Langchain from "./pages/Langchain";
import EditKnowledgebase from './pages/EditKnowledgebase';
import EmbedAIOnboarding from "./pages/EmbedAIOnboarding";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [loggedInSet, setLoggedInSet] = useState(false)
  const [userId, setUserId] = useState("")
  const [authUrl, setAuthUrl] = useState("")
  const [showBanner, setShowBanner] = useState(false);
  var url_host = window.location.hostname;
  // window.OneSignal = window.OneSignal || [];
  // const OneSignal = window.OneSignal;
  useEffect(()=>{
    OneSignal.init({ appId: '21c2f92f-e0f0-4cd4-b010-501e6a8bbd2b', allowLocalhostAsSecureOrigin: true});
    
  })
  useEffect(() => {
    axios.get("/isLoggedIn").then((res)=>{
      setIsLoggedIn(res.data.isLoggedIn)
      setLoggedInSet(true)
      setUserId(res.data.userId)
      setAuthUrl(res.data.auth_url)
    }).catch((err)=>{
      console.log(err)
    })
    window.setTimeout(()=>setShowBanner(true),30000)

  }, [])
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
        <Route exact path='/' element={url_host == "heybot.thesamur.ai" ? (<HeyBot/>):url_host == "babyagi.thesamur.ai" ? (<BabyAGIFrontend/>):url_host == "langchain.thesamur.ai" ? (<Langchain/>):url_host == "embedai.thesamur.ai" ? (<EmbedAIWelcome/>):url_host == "pdftochatbot.thesamur.ai" ? (<PDFtoChatbot/>):url_host == "notiontochatbot.thesamur.ai" ? (<NotiontoChatbot/>):url_host == "shopifyaichatbot.thesamur.ai" ? (<ShopifytoChatbot/>):url_host == "wordpressaichatbot.thesamur.ai" ? (<WordpressAIChatbot/>):url_host == "privategpt.thesamur.ai" ? (<PrivateGPT/>):url_host == "chatcsv.thesamur.ai" ? (<CSVAgent/>): url_host == "chatgptplugins.thesamur.ai" ? (<Plugins/>): url_host == "developer.thesamur.ai" ? (<DevPlugins/>): url_host == "autogpt-plugins.thesamur.ai" ? (<AutoGPTPlugins/>): url_host == "autogpt.thesamur.ai" ? (<AGI/>): url_host == "camelagi.thesamur.ai" ? (<AgentConvo/>): url_host == "ritebot.thesamur.ai" ? (<RiteBot/>): url_host == "memegpt.thesamur.ai" ? (<MemeJourney/>): url_host == "lang.thesamur.ai" ? (<LanguageGPT/>): url_host == "mygpt.thesamur.ai" || url_host == "thesamur.ai" || url_host == "www.thesamur.ai" ? (<ChatGPTClone/>):(<ChatGPTClone/>)}/>
        <Route exact path='/summary' element={<Home/>}/>
        <Route exact path='/engagement' element={<Home/>}/>
        <Route exact path='/retention' element={<Home/>}/>
        <Route exact path='/dashboard/automation' element={<Home/>}/>
        <Route exact path='/automations' element={<Home/>}/>
        <Route exact path='/members' element={<Home/>}/>
        <Route exact path='/activities' element={<Home/>}/>
       {loggedInSet && <Route exact path='/chat' element={isLoggedIn?<Chatbot userId={userId}/>:<Navigate replace to={"/login"} />}/>}
        <Route exact path='/login' element={<LoginSignup authUrl={authUrl}/>}/>
        <Route exact path='/appsumo_signup' element={<AppsumoSignup/>}/>
        <Route exact path='/faq' element={<FAQ/>}/>
        <Route exact path='/heybot' element={<HeyBot/>}/>
        <Route exact path='/heybot/embed' element={<BotEmbed/>}/>
        <Route exact path='/heybot/faq' element={<HeybotFAQ/>}/>
        <Route exact path='/share/:slug' element={<BotEmbed/>}/>
        <Route exact path='/heybot' element={<HeyBot/>}/>
        <Route exact path='/mygpt' element={<ChatGPTClone/>}/>
        <Route exact path='/mygpt/faq' element={<MyGPTFAQ/>}/>
        <Route exact path='/mygpt/share' element={<ChatShare/>}/>
        <Route exact path='/plugins' element={<Plugins/>}/>
        <Route exact path='/ritebot' element={<RiteBot/>}/>
        <Route exact path='/ritebot/faq' element={<RiteBotFAQ/>}/>
		    <Route exact path='/memejourney' element={<MemeJourney/>}/>
        <Route exact path='/memejourney/faq' element={<MemeJourneyFAQ/>}/>
		<Route exact path='/babyagi' element={<BabyAGIFrontend/>}/>
		<Route exact path='/agi' element={<AGI/>}/>
		<Route exact path='/csvagent' element={<CSVAgent/>}/>
		<Route exact path='/chatyoutube' element={<ChatYoutube/>}/>
        <Route exact path='/chatpdf' element={<ChatPDF/>}/>
        <Route exact path='/privategpt' element={<PrivateGPT/>}/>		
		<Route exact path='/agiplugins' element={<AutoGPTPlugins/>}/>
		<Route exact path='/languagegpt' element={<LanguageGPT/>}/>
		<Route exact path='/conversation' element={<AgentConvo/>}/>
		<Route exact path='/devplugins' element={<DevPlugins/>}/>		
		<Route exact path='/conversation/share' element={<AgentConvoShare/>}/>
		<Route exact path='/app' element={<HeyBotNew/>}/>
    <Route exact path='/app/welcome' element={<EmbedAIWelcome/>}/>
    <Route exact path='/pdftochatbot' element={<PDFtoChatbot/>}/>    
		<Route exact path='/embedai/embed/:slug' element={<HeyBotNewEmbed/>}/>
		<Route exact path='/app/customize/:slug' element={<HeyBotNewCustomize/>}/>
    <Route exact path='/langchain' element={<Langchain/>}/>    
    <Route exact path='/crisp/callback' element={<EmbedAIBotSelectCrisp/>}/>    
    <Route exact path='heybotnew/intercom/callback' element={<EmbedAIBotSelectIntercom/>}/>    
    <Route exact path='/onboarding' element={<EmbedAIOnboarding/>}/>    
      </Routes>
      </BrowserRouter>

      {/* <Modal className="banner-modal" centered show={showBanner} onHide={()=>setShowBanner(false)}>
        <Modal.Body className="p-5 position-relative">
          <b style={{cursor:'pointer'}} onClick={()=>setShowBanner(false)} className="position-absolute top-0 end-0 me-4 mt-2">X</b>
          <Stack className="align-items-center" gap={3}>
            <a  href="https://www.producthunt.com/posts/samurai-gpt3-for-your-community?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-samurai&#0045;gpt3&#0045;for&#0045;your&#0045;community" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=376598&theme=light" alt="Samurai&#0032;&#0045;&#0032;GPT3&#0032;for&#0032;your&#0032;community - Automate&#0032;community&#0032;building&#0032;on&#0032;discord | Product Hunt" /></a>
            <a className="discord-invite px-3 py-3 ms-3" target="_blank" href="https://discord.gg/KZSUEjr4TM"><DiscordIcon className="icon me-1"/> Join Our Discord</a>
          </Stack>
        </Modal.Body>
      </Modal> */}
{/* 
      <div className="px-2  px-xl-3  py-xl-1 powered-by ">
        <Stack direction="horizontal" gap={3}>
          <a
              className="text-decoration-none d-flex align-items-center"
              href="https://www.uuki.live"
              target="_blank"
            >
          
              <small >Created by <b className="ps-lg-1 d-lg-none">UUKI</b> </small>
          
              <img className="ms-lg-1 d-none d-lg-block" src={uukiLogo}/>
          
            </a>
            <a className="px-3 py-2 ms-3 text-decoration-none d-none d-xxl-block" target="_blank" href="https://discord.gg/A6EzvsKX4u"><DiscordBlueIcon className="icon me-1"/> Join Our Discord</a>

        </Stack>

      </div> */}

      {/* <a className="producthunt-banner d-none d-xl-block" href="https://www.producthunt.com/posts/samurai-gpt3-for-your-community?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-samurai&#0045;gpt3&#0045;for&#0045;your&#0045;community" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=384855&theme=light" alt="Samurai&#0032;&#0045;&#0032;GPT3&#0032;for&#0032;your&#0032;community - Automate&#0032;community&#0032;building&#0032;on&#0032;discord | Product Hunt" style={{maxWidth:'200px'}}/></a> */}
      {/* <a className="producthunt-banner d-none d-xl-block" href="https://www.producthunt.com/posts/heybot-2?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-heybot&#0045;2" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=381326&theme=light" alt="HeyBot - Website&#0032;to&#0032;Chatbot&#0032;powered&#0032;by&#0032;GPT3 | Product Hunt" style={{maxWidth:'200px'}}/></a> */}
      {/* <a className="producthunt-banner d-none d-xl-block" href="https://www.producthunt.com/posts/memejourney?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-memejourney" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=384855&theme=light"  alt="MemeJourney - Turn&#0032;text&#0032;into&#0032;memes&#0032;using&#0032;ChatGPT | Product Hunt" style={{maxWidth:'200px'}}/></a> */}
	  {/*<a className="producthunt-banner d-none d-xl-block" href="https://www.producthunt.com/posts/chatgpt-plugins-5?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-chatgpt&#0045;plugins&#0045;5" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=386004&theme=light"  alt="ChatGPT&#0032;Plugins - Access&#0032;to&#0032;chatGPT&#0032;plugins&#0032;without&#0032;ChatGPT&#0032;Plus | Product Hunt" style={{maxWidth:'200px'}}/></a> */}
	  {/*<a className="producthunt-banner d-none d-xl-block" href="https://www.producthunt.com/posts/autogpt-an-autonomous-gpt-4?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-autogpt&#0045;an&#0045;autonomous&#0045;gpt&#0045;4" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=388398&theme=light" alt="Autogpt&#0032;&#0045;&#0032;An&#0032;Autonomous&#0032;GPT&#0045;4 - AutoGPT&#0032;in&#0032;the&#0032;browser | Product Hunt" style={{maxWidth:'200px'}} /></a> */}
	  {/* {<a className="producthunt-banner d-none d-xl-block" href="https://www.producthunt.com/posts/camel-agi?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-camel&#0045;agi" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=389872&theme=light" alt="Camel&#0032;AGI - Communicative&#0032;Agents&#0032;on&#0032;GPT | Product Hunt" style={{maxWidth:'200px'}} /></a>} */}
	  {/* <a className="producthunt-banner d-none d-xl-block" href="https://www.producthunt.com/posts/autogpt-plugins?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-autogpt&#0045;plugins" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=393553&theme=light" alt="Camel&#0032;AGI - Communicative&#0032;Agents&#0032;on&#0032;GPT | Product Hunt" style={{maxWidth:'200px'}} /></a> */}
	  {/* <a className="producthunt-banner d-none d-xl-block" href="https://www.producthunt.com/posts/privategpt?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-privategpt" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=395718&theme=light" alt="PrivateGPT - Your&#0032;Offline&#0032;Personal&#0032;AI&#0032;Assistant | Product Hunt" style={{maxWidth:'200px'}} /></a> */}
    </div>
  );
}

export default App;
